<template>
  <el-container class="vehiclemgt-wrapper">
    <!-- <el-header class="mgt-oper-outer" height="32px">
      <div class="left-content"></div>
      <div style="display: flex">
        <el-button @click="onAddGroupClick" type="primary">添加车组</el-button>
        <el-button @click="onAddVehicleClick" type="primary"
          >添加车辆</el-button
        >
        <el-button @click="onTransferClick" type="primary">转移车辆</el-button>
        <el-button @click="onTransferCheckedClick" type="primary"
          >转移选中项</el-button
        >
        <el-button size="small" @click="onSearchClick">查询导出</el-button>
      </div>
    </el-header> -->

    <el-container class="mgt-content-outer" style="padding-bottom: 0">
      <Split direction="row" :sideOffset="0.2">
        <template #first>
          <ModalSideTree
            ref="refSideTree"
            @select-node="selectNodeClick2"
            @handleOperate="handleOperate"
          />
        </template>
        <template #second>
          <!-- <ModalTable
            ref="refTable"
            @on-handle="handleTable"
            @on-selection="onSelectionChange"
          /> -->
          <ModalTable
            ref="refTable2"
            @on-handle="handleTable"
            @onAddVehicleClick="onAddVehicleClick"
            @onTransferClick="onTransferClick2"
            @onTransferCheckedClick="onTransferCheckedClick2"
          />
        </template>
      </Split>
    </el-container>

    <modal-group
      ref="refEditGroup"
      v-model="groupModal.modal"
      :groupId="groupModal.groupId"
      :showParentGroup="showParentGroup"
      @on-ok="onOkGroup"
    ></modal-group>

    <modal-vehicle
      v-model="vehicleModal.modal"
      :groupId="vehicleModal.groupId"
      :groupName="vehicleModal.groupName"
      :vehicleId="vehicleModal.vehicleId"
      @on-ok="onOkVehicle"
      v-if="vehicleModal.modal"
    ></modal-vehicle>

    <modal-transfer
      v-model="transferModal.modal"
      :iProps="transferModal.props"
      @on-ok="onOkTranster"
    ></modal-transfer>
    <modal-search v-model="searchModal.modal"></modal-search>
  </el-container>
</template>

<script>
import ModalSideTree from "./ModalSideTree";
import ModalTable from "./ModalTable2";
import ModalGroup from "@/components/ModalCarGroupInfo";
import ModalVehicle from "@/components/ModalCarInfo2";
import ModalTransfer from "@/components/ModalVehicleTransfer";
import ModalSearch from "./VehicleDataMgtExportModal";
import { mapState } from "vuex";
import Split from "@/components/Split";
import {
  getVehiclesByGroupId,
  delVehicleGroup,
  delteVehicle,
  getVehGroupInfo,
} from "@/api/getManagementData";
import { deleteEquipment } from "@/api/vehicleEquipment";
export default {
  name: "VehicleManagement",
  components: {
    ModalVehicle,
    ModalTransfer,
    ModalGroup,
    Split,
    ModalSideTree,
    ModalTable,
    ModalSearch,
  },
  provide() {
    return {
      reload: this.reload,
    };
  },
  data() {
    return {
      selectGroupId: "",
      expandSide: true,
      searchStr: "",
      groupModal: {
        modal: false,
        groupId: -1,
        groupName: "",
        parentId: -1,
        parentName: "",
      },
      vehicleModal: {
        modal: false,
        vehicleId: 0,
        groupId: -1,
        groupName: "",
      },
      transferModal: {
        modal: false,
        props: {
          transType: "",
          transList: [],
        },
      },
      searchModal: {
        modal: false,
      },
      showParentGroup: true, // 是否展示上级车组
      nodes: "",
    };
  },
  computed: {
    ...mapState(["userInfo"]),
  },
  watch: {},
  mounted() {},

  methods: {
    reload() {
      var that = this;
      this.isRouterAlive = false;
      this.$nextTick(() => {
        that.isRouterAlive = true;
      });
    },
    // 选择的节点事件
    async selectNodeClick(node) {
      this.nodes = JSON.parse(JSON.stringify(node));
      const { nodeType, groupId, vehicleId } = node;
      await this.queryData(groupId);

      if (nodeType === 2) {
        // 选中的车辆
        this.$refs.refTable.setVehicleId(vehicleId);
      }
    },
    async selectNodeClick2(node) {
      this.nodes = JSON.parse(JSON.stringify(node));
      const { nodeType, groupId, vehicleId } = node;
      this.$refs.refTable2.getListData(groupId);
    },

    //再次查询车辆数据
    async onOkVehicle() {
      const { nodeType, groupId, vehicleId } = this.nodes;
      // await this.queryData(groupId);
      // if (nodeType === 2) {
      //   // 选中的车辆
      //   this.$refs.refTable.setVehicleId(vehicleId);
      // }
      this.$refs.refTable2.getListData(groupId);
    },
    // 根据车组ID查询车辆
    async queryData(groupId) {
      try {
        const result = await getVehiclesByGroupId({
          groupIds: groupId,
          factoryNo: "",
        });

        this.$refs.refTable.setTableList(result.obj); // 传入表格数据
      } catch (err) {
        //
        this.$message.warning("查询异常");
      }
    },

    // 树功能点击事件
    handleOperate({ data, type }) {
      const { nodeType } = data;
      const params = { ...data, vehicleId: data.V };
      switch (type) {
        case 0: // 添加事件
          if (nodeType === 1) this.onAddGroupClick(data, true); // 新增车组
          this.showParentGroup = false;

          break;

        case 1: // 编辑事件
          if (nodeType === 1) return this.onAddGroupClick(data, false); //修改车组

          this.onModifyVehicleClick(params); //修改车辆
          break;

        case 2: // 删除事件
          if (nodeType === 1) return this.onDelGroupClick(data); //删除车组

          this.onDelVehicleClick(data); //删除车辆

          break;
      }
    },

    // 表格功能点击事件
    handleTable({ data, type }) {
      switch (type) {
        case 0: // 转移车辆
          this.onTransferSingleClick(data);
          break;

        case 1: // 编辑车辆
          this.onModifyVehicleClick(data);

          break;

        case 2: // 删除车辆
          this.onDelVehicleClick(data);
          break;

        default:
      }
    },

    // 点击编辑车组
    onAddGroupClick(data = {}, type = false) {
      // type:是否添加指定上级
      const { groupId } = data;
      this.showParentGroup = true;

      this.groupModal.groupId = groupId && !type ? groupId : -1;

      if (groupId) return this.getGroupInfoById(groupId, type);

      this.groupModal.modal = true;
    },

    // 根据车组id查询车组信息
    async getGroupInfoById(groupId, type) {
      try {
        const result = await getVehGroupInfo({ groupId: groupId });
        this.$refs.refEditGroup.setEditCarInfo(result.obj, type);
        this.groupModal.modal = true;
      } catch (err) {
        this.$message.warning("查询异常");
      }
    },

    // 点击新增车辆
    onAddVehicleClick() {
      this.vehicleModal.vehicleId = 0;
      this.vehicleModal.groupId = -1;
      this.vehicleModal.groupName = "";
      this.vehicleModal.modal = true;
    },

    // 点击修改车辆
    onModifyVehicleClick(row) {
      this.vehicleModal.vehicleId = row.vehicleId;
      this.vehicleModal.groupId = -1;
      this.vehicleModal.groupName = row.groupName;
      this.vehicleModal.modal = true;
    },

    // 删除弹窗
    onDelVehicleClick(row) {
      this.$confirm(
        "是否删除该设备？删除该车剩余时长将清空且不可退币！",
        "信息",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      )
        .then(() => {
          this.delteVehicleChange(row);
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },

    //删除车辆事件
    async delteVehicleChange(data) {
      const { groupId, groupName, vehicleId } = data;
      try {
        const result = await deleteEquipment({ groupId, vehicleId });
        this.onOkVehicle();
        this.$message.success(result.msg);
      } catch (err) {
        console.error(err);
        this.$message.warning("请求异常");
      }
    },

    // 转移车辆
    onTransferSingleClick(row) {
      // 转移单个车辆
      this.transferModal.props.transList = [row.terminalNo];
      this.openTransfer();
    },

    // 删除车组弹窗
    onDelGroupClick(treeNode) {
      this.$confirm(
        "该车组下的车辆剩余时长将清空且不可退币！该车组及其子车组、车辆都将删除！",
        "信息",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      )
        .then(() => {
          this.ondeleteChange(treeNode);
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },

    // 删除车组事件
    async ondeleteChange(item) {
      const { groupId } = item;

      try {
        const result = await delVehicleGroup({ groupId });
        this.$refs.refSideTree.onloadTree();
        this.$message.success(result.msg);
      } catch (err) {
        console.error(err);
        this.$message.warning("请求异常");
      }
    },

    // 转移车辆
    openTransfer() {
      this.transferModal.props.transType = "";
      if (this.transferModal.props.transList.length) {
        this.transferModal.props.transType = "terminalNo";
      }
      this.transferModal.modal = true;
    },

    // 成功添加车组后执行
    onOkGroup() {
      this.$refs.refSideTree.onloadTree();
    },

    // table 勾选数据
    onSelectionChange(list) {
      this.transferModal.props.transList = [];
      this.transferModal.props.transList = list.map((item) => item.terminalNo);
    },

    // 转移选中车辆
    onTransferCheckedClick() {
      if (!this.transferModal.props.transList.length) {
        return this.$message.warning("请选择车辆");
      }
      this.openTransfer();
    },
    onTransferCheckedClick2(row = []) {
      if (!row.length) {
        return this.$message.warning("请选择车辆");
      }
      this.onSelectionChange(row);
      this.openTransfer();
    },

    createStateFilter(queryString) {
      return (state) => {
        return (
          state.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0
        );
      };
    },

    onTransferClick() {
      const { refTable } = this.$refs;
      if (!refTable) return;
      refTable.clearSelection();
      this.transferModal.props.transList = [];

      this.openTransfer();
    },
    onTransferClick2() {
      this.transferModal.props.transList = [];
      this.openTransfer();
    },

    onSearchClick() {
      this.searchModal.modal = true;
    },
    onOkTranster() {
      this.onOkVehicle();
    },
  },
};
</script>

<style lang="scss" scoped>
.vehiclemgt-wrapper {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  background: #ffffff;
  //padding: 12px 15px;
  box-sizing: border-box;
}
.mgt-oper-outer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.left-content {
  display: flex;
  align-items: center;
}

::v-deep .el-table__body-wrapper {
  flex: 1;
}
::v-deep .el-table__cell {
  padding: 8px 0;
}
.el-container {
  background: #fbfafa;
  //padding: 9px 0 0 0;
}
.el-aside {
  background: #eceeef;
}

.el-collapse {
  border: none;
  padding: 0 10px;
  background: #ffffff;
}
.iconClass {
  margin-right: 6px;
}

.mgt-content-outer {
  overflow: auto;
}
</style>
