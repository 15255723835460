<template>
  <div>
    <SimpleTable ref="table" :tableProps="tableProps" :queryFun="queryFun">
      <div style="margin-left: 12px" slot="left-r" slot-scope="{ data }">
        <el-button
          v-if="hasPerms('M12SM02_1')"
          @click="$emit('onAddVehicleClick')"
          class="add-btn"
          icon="el-icon-circle-plus-outline"
          >添加车辆</el-button
        >
        <el-button
          v-if="hasPerms('M12SM02_6')"
          @click="$emit('onTransferClick')"
          class="base-btn change-btn"
          icon="el-icon-refresh"
          >转移车辆</el-button
        >
        <el-button
          v-if="hasPerms('M12SM02_6')"
          @click="$emit('onTransferCheckedClick', data.selectRows)"
          class="base-btn change-btn"
          icon="el-icon-refresh"
          >转移选中项</el-button
        >
      </div>
      <template slot="right-r" slot-scope="{ data }"
        ><el-button
          v-if="hasPerms('M12SM02_5')"
          @click="exportData(data.params)"
          icon="el-icon-upload2"
          class="export-btn"
          >导出</el-button
        ></template
      >
      <div slot="action" slot-scope="{ data }">
        <el-button type="text" @click="toVehicleDetails(data.row)"
          >查看详情</el-button
        >
        <el-button type="text" @click="downloadQrCode(data.row)"
          >二维码</el-button
        >
        <el-button
          v-if="hasPerms('M12SM02_6')"
          type="text"
          @click="handleTable(data.row, 0)"
          >转移</el-button
        >
        <el-button
          v-if="hasPerms('M12SM02_2')"
          type="text"
          @click="handleTable(data.row, 1)"
          >修改</el-button
        >
        <el-button
          v-if="hasPerms('M12SM02_3')"
          type="text"
          style="color: #f64a2d"
          @click="handleTable(data.row, 2)"
          >删除</el-button
        >
      </div>
    </SimpleTable>
  </div>
</template>

<script>
import SimpleTable from "@/components/SimpleTable";
import {
  getVehiclesByGroupId,
  getVehicleQrCode,
} from "@/api/getManagementData";
import { getEquipmentList, exportEquipment } from "@/api/vehicleEquipment";
import { hasPerms, dataPermissions } from "@/utils/auth";
export default {
  components: { SimpleTable },
  data() {
    return {
      hasPerms: hasPerms,
      groupId: "",
      tableProps: {
        fuzzyQueryKey: "fuzzySearch",
        currentPageKey: "index",
        currentSizeKey: "size",
        totalKey: "total",
        height: 430,
        defaultColumnWidth: 100,
        immediateQuery: false,
        isFuzzyQuery: false,
        searchConfigs: [
          {
            label: "设备名称",
            type: "input",
            modelKey: "plate",
          },
          {
            label: "车架号",
            type: "input",
            modelKey: "frameNo",
          },
          {
            label: "设备类型",
            type: "input",
            modelKey: "machineryProductType",
          },
          {
            label: "客户名称",
            type: "input",
            modelKey: "customerName",
          },
          {
            label: "终端编号",
            type: "input",
            modelKey: "terminalNo",
          },
          {
            label: "安装人",
            type: "input",
            modelKey: "installPerson",
          },
          {
            label: "安装时间",
            type: "daterange",
            daterangeKeys: ["installDateBegin", "installDateEnd"],
            // daterangeFormats: ["YYYY-MM-DD", "YYYY-MM-DD"],
            modelKey: "installT",
          },
          {
            label: "项目经理",
            type: "input",
            modelKey: "projectManager",
          },
          {
            label: "创建人",
            type: "input",
            modelKey: "createUserName",
          },
          {
            label: "创建时间",
            type: "daterange",
            daterangeKeys: ["createTimeBegin", "createTimeEnd"],
            // daterangeFormats: ["YYYY-MM-DD", "YYYY-MM-DD"],
            modelKey: "createT",
          },
          {
            label: "使用状态",
            type: "select",
            modelKey: "onState",
            option: [
              { dictKey: 1, dictValue: "正常" },
              { dictKey: 2, dictValue: "维修" },
              { dictKey: 3, dictValue: "停用" },
            ],
          },
        ],
        columnProps: [
          {
            prop: "",
            label: "序号",
            width: "55",
            type: "index",
            isShow: true,
          },
          {
            prop: "plate",
            label: "设备名称",
            width: "",
            type: "",
            isShow: true,
          },

          {
            prop: "groupName",
            label: "所属车组",
            width: "",
            type: "",
            isShow: true,
          },
          {
            prop: "frameNo",
            label: "车架号",
            width: "",
            type: "",
            isShow: true,
          },
          {
            prop: "machineryProductType",
            label: "设备类型",
            width: "",
            type: "",
            isShow: true,
          },
          {
            prop: "terminalNo",
            label: "终端编号",
            width: "",
            isShow: true,
          },
          {
            prop: "customerName",
            label: "客户名称",
            width: "",
            type: "",
            isShow: true,
          },
          {
            prop: "projectManager",
            label: "项目经理",
            width: "",
            type: "",
            isShow: true,
          },
          {
            prop: "deptName",
            label: "所属部门",
            width: "",
            type: "",
            isShow: true,
          },
          {
            prop: "installPerson",
            label: "安装工",
            width: "",
            type: "",
            isShow: true,
          },
          {
            prop: "installDate",
            label: "安装时间",
            width: "",
            type: "",
            isShow: true,
          },
          {
            prop: "onState",
            label: "使用状态",
            width: "",
            type: "",
            isShow: true,
          },
          {
            prop: "createUserName",
            label: "创建人",
            width: "",
            type: "",
            isShow: true,
          },
          {
            prop: "createTime",
            label: "创建时间",
            width: "",
            type: "",
            isShow: true,
          },
          {
            prop: "action",
            label: "操作",
            width: "240",
            type: "",
            slot: true,
            isShow: true,
            fixed: "right",
          },
        ],
      },
    };
  },
  methods: {
    async queryFun(queryParams, extraParams) {
      let res = await getEquipmentList({
        groupId: this.groupId,
        ...queryParams,
      });
      return {
        localData: res?.obj?.list || [],
        total: res?.obj?.total || 0,
      };
    },
    getListData(params) {
      this.groupId = params;
      this.$refs.table.getData();
    },
    // 下载二维码
    downloadQrCode(row) {
      getVehicleQrCode({
        frameNo: row.frameNo,
        vehicleId: row.vehicleId,
        plate: row.plate,
      }).then((response) => {
        // 创建一个临时的URL，用于下载
        const url = window.URL.createObjectURL(
          new Blob([response], { type: "image/png" })
        );
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${row.plate}.png`);
        document.body.appendChild(link);
        link.click();
        URL.revokeObjectURL(link.href);
      });
    },
    // 表格操作事件
    handleTable(data, type) {
      this.$emit("on-handle", { data, type });
    },
    exportData(params) {
      exportEquipment({ groupId: this.groupId, ...params });
    },
    toVehicleDetails(item) {
      sessionStorage.setItem("vehicleIdList", JSON.stringify([item.vehicleId]));
      this.$nextTick(() => {
        this.$router.push({
          path: "/SCDetails",
          query: {
            vehicleIndex: 0,
          },
        });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.base-btn {
  font-weight: 400;
  font-size: 14px;
}
.add-btn {
  background: #00bc0d;
  font-weight: 400;
  font-size: 14px;
  color: #ffffff;
  border-color: #00bc0d;
}
.export-btn {
  background: #4278c9;
  font-weight: 400;
  font-size: 14px;
  color: #ffffff;
  border-color: #4278c9;
}
.change-btn {
  background: #4278c9;
  color: #ffffff;
  border-color: #4278c9;
}
</style>
