<template>
  <div class="upload-box">
    <div
      class="upload-box-show"
      v-for="(item, index) in fileList2"
      :key="index"
    >
      <FileIcon2
        :showDelet="false"
        :suffix="item.fileType"
        :fileUrl="item.attachmentUrl"
      ></FileIcon2>
      <div class="mask">
        <i style="cursor: pointer" class="el-icon-search"></i>
        <i
          @click.stop="deletFile(index)"
          style="cursor: pointer"
          class="el-icon-delete"
        ></i>
      </div>
    </div>
    <el-upload
      action="#"
      :multiple="multiple"
      :show-file-list="false"
      :http-request="() => {}"
      :on-change="
        (file, fileList) => {
          addFile(file, fileList);
        }
      "
    >
      <div class="upload-box-add">
        <i class="el-icon-plus"></i>
      </div>
    </el-upload>
  </div>
</template>

<script>
// import { uploadFile } from "@/utils/index";
import { OSSDirectPass } from "@/api/oss.js";
import FileIcon2 from "./FileIcon2";
export default {
  // uploadFile({ accept: "image/*", fileSize: 10 }).then(async (res) => {
  //       let url = await OSSDirectPass({
  //         name: res.name,
  //         raw: res,
  //       });
  //       this.dataForm.thumbnail = url;
  //       this.$forceUpdate();
  //       this.$emit("submit");
  //     });

  name: "BoxUpload",
  components: { FileIcon2 },
  props: {
    multiple: {
      type: Boolean,
      default: false,
    },
    count: {
      type: Number,
      default: 1,
    },
    fileList: {
      type: Array,
      default: () => {
        return [
          // {
          //   attachmentName:'',
          //   attachmentUrl:'',
          //   fileType:''
          // }
        ];
      },
    },
  },
  model: {
    prop: "fileList",
    event: "change",
  },
  computed: {
    fileList2: {
      get() {
        return this.fileList;
      },
      set(val) {
        this.$emit("change", val);
      },
    },
  },
  data() {
    return {};
  },
  methods: {
    async addFile(file, fileList) {
      if (this.count !== 0 && this.fileList2.length >= this.count) {
        return this.$message.error("最多上传" + this.count + "个文件");
      }
      // uploadFile({ accept: "*", fileSize: 10 }).then(async (res) => {
      //   let url = await OSSDirectPass({
      //     name: res.name,
      //     raw: res,
      //   });
      //   this.fileList2.push({
      //     attachmentName: res.name,
      //     attachmentUrl: url,
      //     fileType: res.name.split(".").pop(),
      //   });
      // });
      let url = await OSSDirectPass({
        name: file.name,
        raw: file.raw,
      });
      this.fileList2.push({
        attachmentName: file.name,
        attachmentUrl: url,
        fileType: file?.name?.split(".").pop(),
      });
    },
    deletFile(inex) {
      this.fileList2.splice(inex, 1);
    },
  },
};
</script>

<style lang="scss" scoped>
.upload-box {
  display: flex;
  &-add {
    cursor: pointer;
    width: 88px;
    height: 88px;
    background: #ffffff;
    border-radius: 4px 4px 4px 4px;
    border: 1px dashed #cccccc;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &-show {
    width: 88px;
    height: 88px;
    border-radius: 4px 4px 4px 4px;
    margin-right: 10px;
    position: relative;
    overflow: hidden;
    &:hover {
      .mask {
        display: flex;
      }
    }
  }
}
.mask {
  display: none;
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  top: 0;
  left: 0;
  color: #ffffff;
  align-items: center;
  justify-content: space-around;
}
</style>
