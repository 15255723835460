<template>
  <div class="side-tree">
    <el-collapse v-model="activeNames">
      <el-collapse-item name="1">
        <template slot="title">
          <span style="font-size:16px;font-weight: 700;margin-left:10px">
            <label>工程机械列表</label>
          </span>
        </template>
        <GroupVehicleTree
          ref="refTree"
          class="TreeOuter"
          defaultExpandAll
          :showSearch="true"
          @node-click="onNodeClick"
          @searchSelect="onSelectNode"
        >
          <template v-slot="{data}">
            <div
              class="NodeLabel"
              :title="getNodeLabel(data)"
            >{{getNodeLabel(data)}}</div>
          </template>

          <template #action="{data}">
            <div class="actionCls">
              <Iconfont
                name="icon-fenzuzengjia"
                :size="12"
                :mRight="7"
                title="新增"
                @click="handleOperate(data, 0)"
              ></Iconfont>

              <Iconfont
                v-if="!isRootGroup(data)"
                name="icon-fenzuxiugai"
                :size="12"
                :mRight="7"
                title="编辑"
                @click="handleOperate(data, 1)"
              ></Iconfont>
              <Iconfont
                v-if="!isRootGroup(data)"
                name="icon-qingkong"
                :size="12"
                :mRight="7"
                title="删除"
                @click="handleOperate(data, 2)"
              ></Iconfont>
            </div>
          </template>
        </GroupVehicleTree>
      </el-collapse-item>
    </el-collapse>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import GroupVehicleTree from '@/components/VehicleGroupTree';

export default {
  name: 'ModalSideTree',

  components: {
    GroupVehicleTree,
  },

  data() {
    return {
      activeNames: '1',
    };
  },
  computed: {
    ...mapState(['userInfo']),
  },

  mounted() {
  },
  methods: {

    /**
     * 非总帐号, 根车组不可删除
     */
    isRootGroup(groupObj) {
      const { userInfo } = this;
      return userInfo.userId !== 1 && groupObj.parentId === -1;
    },

    getNodeLabel(data) {
      // 车组
      if (data.nodeType === 1) return data.groupName;
      // 车辆
      if (data.nodeType === 2) return data.P;
      // 通道号
      if (data.nodeType === 2) return data.label;
    },
    // 选择节点事件
    onNodeClick(data) {
      this.$emit('select-node', data);
    },
    onSelectNode(data) {
      this.$emit('select-node', data);
    },

    // 功能点击事件
    handleOperate(data, type) {
      this.$emit('handleOperate', { data, type });
    },

    onloadTree() {
      this.$refs.refTree.reloadData();
    },
  }

}
</script>

<style lang="scss" scoped>
.side-tree {
  width: 100%;
  height: 100%;
  background: #ffffff;
}

.NodeLabel {
  width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  position: relative;
  & > label {
    margin: 0 3px;
    position: absolute;
    right: 4px;
    & > span {
      margin: 0 4px;
      z-index: 60;
    }
  }
}
.actionCls {
  display: flex;
  justify-content: flex-end;
}

::v-deep .el-collapse {
  border: none;
  height: 100%;
  .el-collapse-item {
    height: 100%;
    .el-collapse-item__wrap {
      height: calc(100% - 48px);
      .el-collapse-item__content {
        padding: 0;
        height: 100%;
      }
    }
  }
}
</style>