<template>
  <div v-if="modal"
    class="modal-searchveh-wrapper">
    <el-dialog :visible="modal"
      ref="vehDom"
      class="modal-searchveh-center"
      @close="onModalChange"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      append-to-body
      destroy-on-close
      width="70%">
      <div slot="title"
        class="header-title">
        <span>搜索车辆资料</span>
      </div>
      <!-- <div slot="header" class="modal-header">
        <span>搜索车辆资料</span>
        <span class="exit-icon" title="关闭" @click="onModalChange(false)">
          <icon size="32" type="ios-close" />
        </span>
      </div>-->
      <div class="modal-main">
        <div class="oper-outer">
          <div>
            <span>添加开始时间：</span>
            <el-date-picker @change="startDate"
              v-model="searchParams.startTime"
              format="yyyy-MM-dd HH:mm:ss"
              value-format="yyyy-MM-dd HH:mm:ss"
              type="date"
              placeholder="开始时间"
              style="width: 200px;margin-right:10px;"></el-date-picker>
            <span>添加结束时间：</span>
            <el-date-picker @change="endDate"
              v-model="searchParams.endTime"
              format="yyyy-MM-dd 23:59:59"
              value-format="yyyy-MM-dd 23:59:59"
              type="date"
              placeholder="结束时间"
              style="width: 200px;"></el-date-picker>
          </div>
          <span style="margin-left:20px">车组：</span>
          <el-input v-model="groupStr"
            style="width:200px">
            <SelectGroupPopover @nodeClick="choiceGroup"
              slot="append"></SelectGroupPopover>
          </el-input>

          <div class="oper-btns">
            <el-button style="font-size: 14px;"
              type="primary"
              icon="ios-search"
              @click="getReport">搜索</el-button>
            <Export style="display: inline-block;"
              :disabled="!tableDataHas"
              title="车辆资料"
              exportUrl="excelExport/exportExcel.do"
              :params="{
                type: 3,
                startTime: searchParams.startTime,
                endTime: searchParams.endTime,
                groupId: searchParams.groupId
              }"></Export>
          </div>
        </div>
        <el-row class="content-table">
          <el-table stripe
            border
            size="mini"
            :data="data"
            style="width: 100%"
            height="490px">
            <el-table-column label="序号"
              type="index"
              width="50px"></el-table-column>
            <el-table-column v-for="(item,index) in columns"
              :key="index"
              :prop="item.key"
              :label="item.title"
              width="180"
              :align="item.align"></el-table-column>
            <el-table-column label="车辆状态"
              prop="vehicleStatus"
              width="180"
              align="center"
              :formatter="formatCol"></el-table-column>
          </el-table>

          <div class="table-page">
            <el-pagination layout="total, sizes, prev, pager, next"
              :total="tablePage.total"
              :current-page="tablePage.pageNumber"
              @current-change="changePage"
              @size-change="pageSizeChange"
              :page-size="tablePage.pageSize"
              :page-sizes="[50, 100, 200]"></el-pagination>
          </div>
        </el-row>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { uuid } from '@/utils/uuid';
import SelectGroupPopover from "@/components/SelectGroupPopover";
import { findVehicleInfo } from "@/api/getManagementData.js";
import { exportExcel } from "@/api/excelExport-api";
import moment from 'dayjs';

import Export from "@/components/Export";

export default {
  name: "VehicleDataMgtExportModal",
  components: {
    Export: Export,
    SelectGroupPopover
  },
  model: {
    prop: "modal",
    event: "modal-change"
  },
  props: {
    modal: {
      type: Boolean,
      default: false
    },
    iProps: {
      //输入参数
      type: Object,
      default() {
        return {};
      }
    }
  },
  data() {
    return {
      groupStr: '',
      snData: {
        snNo: "",
        uuid: uuid()
      },
      tableLoading: false,
      searchParams: {
        groupId: "",
        startTime: this.getStartTime(),
        endTime: this.getEndTime(),
        pageNumber: 1,
        pageSize: 100
      },
      data: [],
      columns: [
        // {
        //   title: "序号",
        //   type: "index",
        //   width: 80,
        //   align: "center"
        // },
        {
          title: "设备名称",
          key: "plate",
          align: "center",
          width: 100
        },
        {
          title: "所属车组",
          key: "groupName",
          align: "center",
          width: 100
        },
        {
          title: "车牌颜色",
          key: "plateColor",
          align: "center",
          width: 100
        },
        {
          title: "SIM卡号",
          key: "sim",
          align: "center",
          width: 120
        },
        {
          title: "终端编号",
          key: "terminalNo",
          align: "center",
          width: 120
        },
        {
          title: "设备类型",
          key: "terminalType",
          align: "center",
          width: 100
        },
        {
          title: "添加时间",
          key: "createTime",
          align: "center",
          minWidth: 160
        },
        // {
        //   title: "车辆状态",
        //   key: "vehicleStatus",
        //   align: "center",
        //   width: 100,
        //   // render: (h, params) => {
        //   //   return h("div", [
        //   //     h(
        //   //       "span",
        //   //       ["从未上线", "行驶", "停车", "离线"][params.row.vehicleStatus]
        //   //     )
        //   //   ]);
        //   // }
        // },
        {
          title: "最后在线时间",
          key: "gpsTime",
          align: "center",
          width: 160
        },
        {
          title: "ICCID",
          key: "iccid",
          align: "center",
          width: 120
        },
        {
          title: "终端ID",
          key: "terminalId",
          align: "center",
          width: 120
        }
      ],
      tablePage: {
        //分页
        pageSizeOpts: [50, 100, 200],
        pageNumber: 1,
        pageSize: 100,
        total: 0
      }
    };
  },
  computed: {
    dialogVisible() {
      return this.visible;
    },
    tableDataHas() {
      return this.tablePage.total > 0;
    }
  },
  watch: {
    modal(val) {
      if (!val) return;
      let dom = this.$refs.vehDom;
    }
  },
  methods: {
    getStartTime() {
      let a = moment(new Date()).format("YYYY-MM-DD 00:00:00");
      return a;
    },
    getEndTime() {
      let a = moment(new Date()).format("YYYY-MM-DD 23:59:59");
      return a;
    },
    formatCol(row, col, cell) {
      switch (cell) {
        case 0: return '怠速';
        case 1: return '行驶';
        case 2: return '作业';
        case 3: return '通电';
        case 4: return '熄火';
        case 5: return '离线';
        case 6: return '从未上线';
        default: return '从未上线';
      }
    },
    onModalChange(modal, eventName = "on-cancel", ...options) {
      this.$emit("modal-change", modal);
      this.$emit(eventName, ...options);
    },
    exportData: function () {
      if (this.data.length < 1) {
        this.$message({
          type: 'info',
          message: "导出数据为空，请先查询车辆数据！"
        });
        return;
      }
      exportExcel({
        startTime: this.searchParams.startTime,
        endTime: this.searchParams.endTime,
        groupId: this.searchParams.groupId,
        type: 3
      }).then(res => {
        let a = document.createElement("a");
        a.href = res;
        a.target = "_blank";
        a.download = `车辆资料数据${ moment(new Date()).format("YYYYMMddHHmmss") }.xls`;
        a.click();
      });
      return;
    },
    choiceGroup(data) {
      this.groupStr = data.groupName;
      this.searchParams.groupId = data.groupId;
      // if (data.type == "0") {
      //   this.searchParams.groupId = "";
      // } else {
      //   this.searchParams.groupId = data.data.groupId;
      // }
    },
    getReport: function () {

      this.tablePage.pageNumber = 1;
      this.searchParams.pageNumber = 1;
      this.getSearchData(this.searchParams);
    },
    getSearchData: function (params) {
      if (new Date(params.startTime).getTime() > new Date(params.endTime).getTime()) {
        this.$message({
          type: 'info',
          message: "开始时间不能大于结束时间！"
        });
        return;
      }
      this.tableLoading = true;
      findVehicleInfo({
        groupId: params.groupId,
        startTime: params.startTime,
        endTime: params.endTime,
        pageNumber: params.pageNumber,
        pageSize: params.pageSize
      }).then(res => {
        console.log(res);
        if (res.flag == 1) {
          this.data = res.obj.vehicleInfo;
          this.tablePage.total = res.obj.total;
          if (this.data.length <= 0) {
            this.$message({
              type: 'info',
              message: "请求数据为空！"
            });
          }
          this.tableLoading = false;
        } else {
          this.$message({
            type: 'error',
            message: res.msg
          });
          this.tableLoading = false;
        }
      });
    },
    startDate(date) {
      this.searchParams.startTime = date;
    },
    endDate(date) {
      this.searchParams.endTime = date;
    },
    // 分页  改变每页数量
    pageSizeChange(pageSize) {
      this.tablePage.pageSize = pageSize;
      this.searchParams.pageSize = pageSize;
      this.changePage(1);
    },
    // 分页，改变页数
    changePage(current) {
      this.tablePage.pageNumber = current;
      this.searchParams.pageNumber = current;
      this.getSearchData(this.searchParams);
    }
  }
};
</script>


<style lang="scss" scoped>
.exportVehicle {
  .el-dialog__body {
    padding: 0px !important;
  }
}
.modal-searchveh-wrapper {
  position: relative;
}

.modal-searchveh-center {
  display: flex;
  align-items: center;
  justify-content: center;

  .modal-header {
    position: relative;
  }
  .exit-icon {
    position: absolute;
    right: 0px;
    top: -6px;
    cursor: pointer;
  }
  .exit-icon:hover {
    background-color: #d01212;
    color: white;
  }

  .modal-main {
    // height: calc(100vh - 310px);
    font-size: 14px;
    position: relative;
    display: flex;
    flex-direction: column;
    .content-table {
      // height: calc(100% - 100px);
      & > div:first-child {
        height: calc(100% - 40px);
        position: relative;
        .ivu-table-tip,
        .ivu-table-body {
          height: calc(100% - 41px);
          overflow: auto;
        }
      }
    }
    .table-page {
      height: 40px;
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }
  }

  .oper-outer {
    position: relative;
    height: 100px;
    & > div {
      margin: 8px 20px;
    }
  }
  .oper-btns {
    position: absolute;
    right: 0px;
    bottom: 0px;
  }
  .bottom {
    position: relative;
    height: 50px;
    width: 100%;
    display: inline-flex;
    justify-content: flex-end;
    align-items: center;
    border-top: 1px solid #e8eaec;
    & > button {
      margin: 0 3px;
    }
  }

  @media (max-width: 900px) {
    .ivu-modal {
      width: calc(100vw - 40px) !important;
    }
    .modal-main {
      height: calc(100vh - 70px);
    }
  }
}
::v-deep .el-dialog__wrapper {
  .el-dialog__body {
    padding: unset !important;
  }
}
.header-title {
  background: #212d3e;
  color: #fff;
  padding: 15px 20px;
  line-height: normal;
}
::v-deep .el-dialog__header {
  padding: 0;
}
</style>
