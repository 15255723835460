<template>
  <div v-if="modal"
    class="modal-vehtransfer-wrapper">
    <el-dialog :visible="modal"
      class="modal-vehtransfer-center"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      append-to-body
      width="560px"
      destroy-on-close
      @close="onModalChange">
      <div slot="title"
        class="header-title">
        <span>车辆转移</span>
      </div>
      <div class="modal-main">
        <div class="m-row">
          <label class="m-row-label">目标车组：</label>
          <!-- <SelectGroupTree style="width:350px"
            :multipleProp="{ noGroup: true }"
            placeholder="车组"
            singleGroup
            :inputVehicle="false"
            @on-select="onSelect"
            @select-group="dbConfirm" /> -->
            <el-input v-model="groupStr"
                  disabled>
                  <SelectGroupPopover @nodeClick="onConfirmClick"
                    slot="append"></SelectGroupPopover>
                </el-input>
        </div>
        <div class="m-row">
          <label class="m-row-label">转移方式：</label>
          <el-select class="m-row-content"
            v-model="transferParams.transType"
            :disabled="!!iProps.transList.length"
            size="mini">
            <el-option v-for="item in transTypeList"
              :key="item.type"
              :label="item.label"
              :value="item.type"></el-option>
          </el-select>
        </div>
        <div class="m-row">
          <label class="m-row-label">{{transTypeList.find(p=>p.type===transferParams.transType).title}}：</label>
          <el-input class="m-row-content"
            type="textarea"
            :disabled="!!iProps.transList.length"
            :rows="6"
            :placeholder="transTypeList.find(p=>p.type===transferParams.transType).placeholder"
            v-model="transferParams.list"></el-input>
        </div>
      </div>
      <div class="bottom">
        <el-button type="text"
          @click="onModalChange(false)">取消</el-button>
        <el-button type="primary"
          :disabled="!transferParams.list"
          @click="onSaveData">保存</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>

/**
 * 转移车辆
 */


import { batchTransfer } from "@/api/getManagementData";
import SelectGroupTree from '@/components/GroupTree/SelectGroupTree';
import SelectGroupPopover from "@/components/SelectGroupPopover";

const transTypeList = [
  { type: 'plate', label: '根据设备名称转移', title: '设备名称', placeholder: '请输入设备名称，多个使用英文逗号分隔' },
  { type: 'terminalNo', label: '根据终端号转移', title: '终端号', placeholder: '请输入终端号，多个使用英文逗号分隔' }
];

export default {
  name: 'VehicleTransferModal',
  components: {
    SelectGroupTree,
    SelectGroupPopover
  },
  model: {
    prop: 'modal',
    event: 'modal-change'
  },
  props: {
    modal: {
      type: Boolean,
      default: false
    },
    iProps: { //输入参数
      type: Object,
      default() {
        return {
          transType: 'plate',
          transList: [],
        };
      }
    }
  },
  data() {
    return {
      groupStr: '',
      transferParams: {
        groupId: -1,
        transType: 'plate',
        list: ''
      },
      transTypeList,
    };
  },
  watch: {
    modal: {
      immediate: true,
      handler: function (val) {
        if (val) {
          //打开操作
          this.groupStr = '';
          this.transferParams.groupId = -1;
          this.transferParams.transType = 'plate';
          this.transferParams.list = '';
          if (this.iProps.transList.length) {
            this.transferParams.transType = this.iProps.transType;
            this.transferParams.list = this.iProps.transList.join(',');
          }
        }
      }
    }
  },
  methods: {
    onModalChange(modal, eventName = 'on-cancel', ...options) {
      this.$emit('modal-change', modal);
      this.$emit(eventName, ...options);
    },
    validateData() {
      const { groupId, transType } = this.transferParams;
      if (groupId === -1) {
        this.$message({
          type: 'warning',
          message: '请选择有效车组'
        });
        return false;
      }
      return true;
    },
    onSaveData() {
      if (!this.validateData()) return;
      batchTransfer(this.transferParams)
        .then(res => {
          res.msg && this.$message({
            type: 'warning',
            message: res.msg
          });
          this.onModalChange(false, 'on-ok');
        });
    },
    onConfirmClick(group) {
      const { groupId, groupName } = group;
      this.transferParams.groupId = ~~groupId;
      this.groupStr = groupName;
    },

    // 选择车组
    dbConfirm(node) {
      this.transferParams.groupId = node.groupId;
      this.groupStr = node.groupName;
    },
    //车组车辆搜索框选择
    onSelect(node) {
      this.transferParams.groupId = node.groupId;
      this.groupStr = node.groupName;
    },
    onSelectQuery(item) {
      const { vehicleId, groupId, plate, groupName, type } = item;
      this.transferParams.groupId = ~~groupId;
      this.groupStr = groupName;
    },
  }
}
</script>

<style lang="scss" scoped>
.modal-vehtransfer-wrapper {
  position: relative;
}

.modal-vehtransfer-center {
  display: flex;
  align-items: center;
  justify-content: center;
  .ivu-row {
    margin-top: 10px;
  }
  .ivu-modal {
    top: 0;
    width: 560px !important;
  }
  .ivu-modal-close {
    top: 8px;
  }
  .ivu-modal-header {
    // padding: 5px 16px;
    font-size: 16px;
    font-weight: bold;
  }
  .ivu-modal-footer {
    text-align: center !important;
    display: inline-flex;
    flex-direction: row-reverse;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
  .ivu-modal-body {
    padding: initial;
  }

  .modal-header {
    position: relative;
  }
  .exit-icon {
    position: absolute;
    right: 0px;
    top: -6px;
    cursor: pointer;
  }
  .exit-icon:hover {
    background-color: #d01212;
    color: white;
  }

  .modal-main {
    height: 255px;
    font-size: 14px;
    position: relative;
  }
  .bottom {
    position: relative;
    height: 50px;
    width: 100%;
    display: inline-flex;
    justify-content: flex-end;
    align-items: center;
    border-top: 1px solid #e8eaec;
    & > button {
      margin: 0 3px;
    }
  }
  .m-row {
    position: relative;
    display: flex;
    margin: 15px 30px;
    align-items: center;
    justify-content: center;
  }
  .m-row-label {
    width: 100px;
    text-align: end;
    margin-right: 10px;
  }
  .m-row-content {
    width: calc(100% - 100px);
    .dropmenu {
      height: 250px;
    }
  }
}
::v-deep .el-dialog__wrapper {
  .el-dialog__body {
    padding: unset !important;
  }
}
.header-title {
  background: #212d3e;
  color: #fff;
  padding: 15px 20px;
  line-height: normal;
}
::v-deep .el-dialog__header {
  padding: 0;
}
</style>

